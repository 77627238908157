#phases, 
#practical-page{
  min-height:100%;
  height:100%;

  .phase-hero, 
  .practical-hero{
    background-image:url("images/phases/PhasesCover.jpg");
    height:100vh;

    h1{
      font-size:80px;
      line-height:96px;
      margin-top:235px;
    }
  }

  .phase-header, 
  .practical-header{
    .back-link{
      height:35px;
      line-height:35px;
      padding-left:50px;
      background-size:35px 35px;
      font-size:20px;
    }
  }

  #intro{
    .container{
      flex-direction:row;
      justify-content:center;
      padding:90px 20px;
    }

    p{
      width:610px;
      font-size:22px;
      line-height:32px;
      flex:none;
      margin-right:74px;
    }

    .chapter-markers{
      width:325px;
      height:440px;
      background-position:left center;
      background-size:370px 440px;
    }
  }

  .phase-section{
    .container{
      justify-content:center;
      flex-direction:row;
      padding:100px 0px;
    }

    h1{
      font-size:40px;      
      margin-bottom:0px;
      width:400px;
    }

    .description-container{
      margin-left:80px;
      width:500px;
    }
  }


  #rain{
    background-image:url("images/phases/bg/PhaseSpacer-0.jpg");
    height:450px;
  }

  #corridor{
    background-image:url("images/phases/bg/PhaseSpacer-1.jpg");
  }

  #mountain{
    background-image:url("images/phases/bg/PhaseSpacer-2.jpg");  
  }

  #escalator{
    background-image:url("images/phases/bg/PhaseSpacer-4.jpg"); 
  }

  #parkinglot{
    background-image:url("images/phases/bg/PhaseSpacer-3.jpg"); 
  }

  #drugs{
    background-image:url("images/phases/bg/PhaseSpacer-5.jpg"); 
  }

  #corridor, 
  #mountain, 
  #split-phase,
  #drugs{
    height:356px;
  }

  #escalator, 
  #parkinglot{
    height:275px;
  }

}