// Place all the styles related to the roles controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/

.role-gallery{
  width:930px;
  margin:32px auto 320px auto;
  background-size:304px 335px;
  min-height:600px;
  padding-top:190px;

  .role-gallery-header{  

    h1{
      font-size:80px;
      margin:0px 0px 150px 0px;
    }
  }

  .role-list{
    width:100%;

    .role-item{
      flex:none;

      a{
        justify-content:flex-start;
        h5{
          font-size:18px;
        }

        &:hover{
          background-color:transparent;
        }
      }

      &.fra-analytikern, 
      &.antikhandlaren, 
      &.droghandlaren{
        a{
          h5{
            font-size:14px;      
          }
        }
      }

      &.even{
        a{
          justify-content:flex-end;
        }
      }

    }
  }
}

.role-page-header{
  position:fixed;
  width:100%;

  .container{
    width:930px; 
  }

  button{
    font-size:20px;
  }

  .close-link{
    font-size:20px;
    height:35px;
    background-size:35px;
    padding:0px 0px 0px 50px; 

    &.mobile{display:none;}
    &.desktop{display:flex;}
  }
}

.role-page{
  top:74px;
  min-height:900px;

  .role-page-hero{
    height:519px;
    background-size:cover;

    h1{
      font-size:80px; 
      margin-top:84px;
      display:block;
    }

    .role-alignment-group{
      width:930px;
      height:250px; 
      margin:0px auto;
      flex-direction:row;
      background-image:none;

      img{
        width:170px;
        height:188px;
        flex:none;
        margin:0px 25px;
        display:block;
      }

      h3{
        flex: 1 0 33%;
        font-size:40px;

        &:nth-child(1){
          text-align:right;
        }

        &:nth-child(3){
          color:#fff;
        }
        
        &:nth-child(4){
          align-self:bottom;
          flex-basis:100%;
          text-align:center;
        }        
      }

      &:before{
        content: none;
      }
    }

    .how-to-read{
      margin-left:initial;
      &.desktop{display:block;}
      &.mobile{display:none;}
    }

  }


  .role-page-intro{
    background-size:186px;

    .container{
      padding:90px 0px 74px 0px;

      p{
        font-size:18px;
        line-height:28px;
      }
    }
  }

  .role-page-lately{

    .container{
      padding:46px 0px 90px 0px;

      h3{
        font-size:30px;
        margin-bottom:15px;
      }

      p{
        font-size:18px;
        line-height:28px;
      }
    }
  }

  .role-page-balance{

    .role-page-balance-content{
      width:440px;
      padding-top:81px;
      padding-bottom:94px;

      p{
        font-size:22px;
        line-height:30px;
      }

    }

    .role-page-strength{
      flex:1 0 50%;
      justify-content:flex-end;

      .role-page-balance-content{
        margin-right:111px;
      }
    }

    .role-page-weakness{
      flex:1 0 50%;

      .role-page-balance-content{
        margin-left:111px;
      }
    }
  }

  .role-page-rankings{
    min-height:750px;

    .container{
      width:1115px;
    }

    .role-page-ranking-item{
      position:absolute;
      width:550px;
      height:254px;
      left:50%;
      padding:20px 49px 29px 49px;

      h3{
        font-size:30px;        
        margin-bottom:15px;
      }

      p{
        font-size:22px;
        line-height:30px;
      }

      &:nth-child(1){
        top:24px;
        margin-left:-560px;
      }

      &:nth-child(2){
        top:231px;
        margin-left:10px;
      }

      &:nth-child(3){
        top:404px;
        margin-left:-560px;
      }
    }
  }

  .role-page-picker{

    h2{
      font-size:30px;
      margin-top:53px;
      margin-bottom:25px;
    }

    .picker-component{

      a{
        width:190px;

        span{
          border-bottom:1px solid transparent;
        }

        &:hover{
          span{
            border-bottom:1px solid #fff;        
          }
        }

        &.not-interested{
          width:140px;
        }

        &.interested{
          width:140px;
        }

      }
    }

    p{
      width:678px;
      font-size:16px;
      line-height:28px;
    }
  }

}


.tutorial-active{
    .role-page{
      .role-page-header{visibility:hidden;}
    }
}

.tutorial-content{
  width:670px;
  margin:70px auto 0px auto;
  background-size:36px 36px;
  padding-left:52px;

  p + p{
    margin-top:25px;
  }

  p:last-child{
    margin-bottom:80px;
  }
}

.tutorial-next{
  width:64px;
  height:64px;
  background-size:64px 64px;
  margin-top:30px;
}

.tutorial-close{
  right:40px;
  background-size:35px 35px;
  line-height:35px;
  font-size:20px;
  padding-left:50px;
  height:35px;
}

.top-mask{
  .tutorial-close{
    bottom:40px;
  }
}