#practical-page{
  .practical-hero{
    background-image:url("images/front/bg/Praktiskt-bg.jpg");
    height:500px;
  }

  .faq-hero{
    background-image:url("images/front/bg/FAQ-bg.jpg");
    height:500px;
  }
}


.practical-section{
  margin-top:90px;

  h3{
    font-size:40px;
    margin-bottom:20px;
  }

  p + h3{
    margin-top:60px;
  }
}