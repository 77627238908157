
#page{
  width:100%;
  min-height:100%;

  .container{
    max-width:1370px;
    margin:0px auto;
    width:930px;
  } 

  .body-text{
    font-size:22px;
    line-height:32px;
  }

  .page-header{
    height:74px;

    .container{
      flex:none;
      margin-left:auto;
      margin-right:auto;
    }

    .header-logo{
      background-size:52px 57px;
      background-position:center left;
      background-image:url('images/shared/logo.svg');
      margin-right:41px;
      margin-left:0px;
      width:52px;
      height:57px;
    }

    .back-link{
      height:35px;
      background-size:35px 35px;      
      font-size:20px;
      padding-left:50px;
      margin-left:0px;
    }

    .mobile-menu, 
    .hamburger{
      display:none;
    }

    .navigation{
      display:block !important;
      margin-right:27px;
      font-size:20px;
      border-bottom:1px solid transparent;

      &.active{
        border-bottom:1px solid white;
      }

      @media screen and (min-width:1100px){
        font-size:22px;
      }
    }

    .button{
      display:block !important;
      margin-left:auto;
      font-size:20px;
      border:#707070 1px solid;
      background-color:#fff;
      border-radius:7px;
      text-transform:uppercase;
      padding:12px 22px;
      font-family:'Cinzel';
    }
  }

  #hero{
    margin-top:74px;
    min-height:700px;
    width:100%;
    background-size:cover;
    position:relative;
    background-image:url('images/front/PosterImage.jpg');

    .container{
      width:930px;
      height:100%;
      position:relative;
      box-sizing:border-box;
      margin:0px auto;
      padding-top:380px;
      display:block;

      background-image:url("images/front/CoverImage-Logo.svg");
      background-repeat:no-repeat;
      background-size:600px;
      background-position:left 200px;


      p{
        font-family:'Raleway';
        font-size:22px;
        line-height:30px;
        margin-top:40px;
      }

      .arrow-container{
        display:block;
        width:67px;
        height:67px;
        position:absolute;
        bottom:50px;
        left:50%; 
        transition: transform 0.2s; 
        transform: scale(1); 
        transform-origin:0% 50%;

        &:hover{
          transform:scale(1.1);
        }
      }

    }  

    .scroll-arrow{
      width:100%;
      height:100%;
      background-image:url('images/front/scroll-arrow.svg');
      background-repeat:no-repeat;
      background-size:contain;
      margin-left:-34px;      
      display:block;
    }
  }

  #teaser{
    background-image: url('images/front/bg/Teaser-Bg.jpg');
    .container{
      padding:253px 0px;
    }

    .body-text{
      line-height:36px;
    }

  }


  #welcome{
    background-color:#132432;
    padding:159px 0px 95px 0px;

    .container{
      flex-direction:row;
      justify-content:space-around;
      width:930px;
      padding:0px 30px;
    }

    .column-spacer{
      flex:1 0; 
      max-width:200px;
      min-width:100px;
    }

    .column{
      flex:none;
      width:400px;
      h3{
        font-size:40px;
      }      
      p{
        font-size:18px;
        line-height:28px;
      }

      &:last-child{
        margin-top:0px;
      }
    }
  }

  blockquote{    
    padding:76px 0px 130px 0px;

    .container{
      justify-content:center;
    }

    img{
      margin-right:93px;
      width:364px;
      height:421px;
    }

    p{
      width:560px;
      font-family:'Raleway';
      font-size:24px;
      line-height:34px;
      margin-top:0px;
      max-width:initial;
      flex:none;
    }

    &.image-right{        
      padding-bottom:100px;
      img{
        margin-left:92px;
        margin-right:0px;
      }
    }

    &.quote4{
      padding-bottom:64px;
    }

    &.quote5{
      padding-top:111px;
      padding-bottom:121px;
    }
  }

  .rainbow{
    position:relative;
    font-size:30px;
    line-height:37px;

    .stripe{
      position:relative;
      height:80px;
      width:100%;
    }

    &::after{
      content:' ';
      width:407px;
      height:448px;
      background-image:url('images/shared/logo.svg');
      position:absolute;
      top:55px;
      left:50%;
      margin-left:-204px;
    }
  }

  #vision{    
    background-image: url('images/front/bg/TopVision-Bg.jpg');
    .container{
      width:930px;      
      padding:107px 0px 97px 0px;
    }

    h3{
      font-size:40px;
      margin-bottom:32px;
    }

  }

  #vision2{
    background-image: url('images/front/bg/BottomVision-Bg.jpg');

    .container{
      width:930px;
      padding:67px 0px 46px 0px;
    }

  }

  #themes{  

    .container{
      width:758px; 
      padding:90px 0px 80px 0px;
    }

    .theme-matrix{            
      width:706px;
      height:324px;
      margin:50px auto 80px auto;
      background-size:706px 324px; 
      background-repeat:no-repeat;
      position:relative;

      .label{
        font-size:16px;
        font-style:italic;
        color:#A2A2A2; 
        position:absolute;
        font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
        display:block;
        width:326px;
        left:50%; 
        margin-left:-178px;
        text-align:center;
        line-height:18px;
      }

      .label:nth-child(1){
        top:37px;
      }

      .label:nth-child(2){
        top:133px;
      }

      .label:nth-child(3){
        top:230px;
      }

      .label:nth-child(4){
        top:324px;
      }
    }

    h4{
      font-size:25px;
      text-align:center;
      line-height:33px;
    }
  }

  #viewpoint{
    .container{
      width:930px;
      padding:101px 0px 100px 0px;
    }
  }

  #rainbow{
    min-height:1182px;

    .container{
      width:913px;
      padding:392px 0px 449px 0px;      
    }

    p{
      font-size:30px;
      line-height:37px;
      font-style:italic;
    }
  }

  #story{
    background-image: url('images/front/bg/Story-Bg.jpg');

    .container{
      width:930px;
      padding:107px 0px 82px 0px;
    }

    h3{
      font-size:40px;
      margin-bottom:32px;
    }

  }

  #meta{
    background-image: url('images/front/bg/Meta-bg.jpg');
    background-size:cover;

    .container{
      width:930px;
      padding:121px 0px 131px 0px;
    }

    h3{
      font-size:40px;
      margin-bottom:32px;
    }

  }

  #paper{

    .container{
      width:930px;
      padding:173px 0px 208px 0px;
    }

  
    h5{
      margin:0px;
      line-height:36px;
      font-size:26px;
    }

    h2{
      font-size:60px;
      margin-bottom:37px;
    }

    p{
      font-size:22px;
      line-height:32px;
    }

    .byline{
      margin-top:97px;
      background-size:120px 120px;
      padding:0px 0px 0px 146px;

      h4{
        line-height:48px;
        font-family:'Georgia';
        font-weight:bold;
        font-size:32px;

        span{
          font-size:28px;
          font-weight:normal;          
        }
      }
    }
  }

  #tattoo{
    background-image: url('images/front/bg/Tatto-Spacer.jpg');
    height:528px;
  }

  .info-section{
    .container{
      width:930px; 
      padding:103px 0px 137px 0px;      
    }

    h3{
      font-size:40px; 
      margin-bottom:32px;
    }

  }

  #hand{
    background-image:url("images/phases/PhasesCover.jpg");
    height:443px;
    background-position:center center;

    a{
      font-size:30px;
    }
  }

  #hands{
    background-image: url('images/front/bg/Hands-Spacer.jpg');
    height:785px;
  }

  #practical{

    .container{
      width:930px;
      padding:103px 0px 110px 0px;
    }

    h3{
      font-size:40px;
      line-height:54px;
      margin-bottom:32px;
    }

  }


#role-types{
    padding:157px 0px 197px 0px;
    background-size:530px 615px;

    h3{
      font-size:40px;
    }

    .container{
      flex-direction:row;
    }

    .column-spacer{
      flex:1 0; 
      min-width:100px;
      max-width:200px;
    }
}

  #info{  
    .container{
      width:930px;
      padding:128px 0px 120px 0px;
    }

    a{
      margin-top:80px;
      font-size:30px;
      line-height:37px;
      width:427px;
    }
  }

  #newsletter{

    h3{
      font-size:40px;
      margin-bottom:32px;
    }
  }

  #dates{

    .container{
      flex-wrap:none;      
    }

    .date-line{
      margin-left:20px;

      li{
        margin-right:100px;
        width:104px;

        &:last-child{
          margin-left:-84px;
          margin-right:0px;

          h2{
            margin-left:66px;
          }

        }
      }
    }
  }

  #signup{

    a{
      font-size:30px;
      line-height:37px;
      width:427px;
    }
  }

  #org-comment{
    p{
      width:500px;
      margin:110px auto 370px auto;
      font-size:22px;
      text-align:left;
    }

    @media screen and (min-width:1100px){
      &:before{
        content: ' '; 
        position:absolute;
        left:0px;
        top:37px;
        width:310px;
        height:377px;
        background-image:url("images/front/left-rainbow-bars.svg");
        background-size:310px 377px;
        background-repeat:no-repeat;
        background-position:right 50px center;
      }
      &:after{
        content: ' '; 
        position:absolute;
        right:-50px;
        top:15px;
        width:310px;
        height:377px; 
        background-image:url("images/front/right-rainbow-bars.svg");
        background-size:310px 377px; 
        background-repeat:no-repeat;
        background-position: left center;
      }
    }
  }

}

